import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import GeneralConfigurationsTab from './general_configurations_tab'
import IndividualConfigurationsTab from './individual_configurations_tab'
const PolicyPremiumConfig = (props) => {
  
  const insurers = props.insurers || []

  return (
    <>
      <Tabs
        defaultActiveKey="general"
        className="policy_premium_tabs"
      >
        <Tab eventKey="general" title="Configuraciones Generales">
          <GeneralConfigurationsTab insurers={insurers}/>
        </Tab>
        <Tab eventKey="individual" title="Configuraciones Particulares">
          <IndividualConfigurationsTab insurers={insurers}/>
        </Tab>
      </Tabs>
    </>
  )
}

export default PolicyPremiumConfig