import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import openInNewTab from '../../../../../../utils/openInNewTab';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { updateEmptyDocumentInsurerExpense } from '../../../../../../routes/requirements'
import axios from 'axios'
import showLoading from '../../../../../../../javascript/utils/showLoading'
import alertWhileRedirect from '../../../../../../../javascript/utils/alertWhileRedirect'
import './DocumentHeaderModalButton.css'

const DocumentHeaderModalButton = ({
  document,
  setDocument,
  index,
  providerBackgroundResponses,
  insurer,
  documentTypeOptions
}) => {
  const [show, setShow] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [flattenedOptions, setFlattenedOptions] = useState([])

  useEffect(() => {
    if (documentTypeOptions) {
      const allOptions = documentTypeOptions.reduce((acc, group) => {
        return [...acc, ...group.options]
      }, [])
      setFlattenedOptions(allOptions)
    }
  }, [documentTypeOptions])

  const providerBackgroundDetails = providerBackgroundResponses?.reduce((acc, response) => {
    if (response?.response_details) {
      return [...acc, ...response.response_details]
    }
    return acc
  }, []) || []

  const [localDocument, setLocalDocument] = useState({
    documentTypeName: document?.documentTypeName || '',
    providerName: document?.providerName || '',
    externalCode: document?.externalCode || '',
    externalCodeDv: document?.externalCodeDv || '',
    date: document?.date || '',
    amount: document?.amount|| ''
  })

  const handleClose = () => {
    setShow(false)
    setSelectedFiles([])
  }

  const handleShow = () => {
    setLocalDocument({
      documentTypeName: document?.documentTypeName || '',
      providerName: document?.providerName || '',
      externalCode: document?.externalCode || '',
      externalCodeDv: document?.externalCodeDv || '',
      date: document?.date || '',
      amount: document?.amount|| ''
    })
    setSelectedFiles([])
    setShow(true)
  }

  // :doctype, :provider_id, :number, :issue_date, :total_required, :prefix
  const prepareDocumentData = () => {
    return {
      doctype: flattenedOptions.find(option => option.label === localDocument.documentTypeName)?.value,
      number: localDocument.externalCode,
      prefix: localDocument.externalCodeDv,
      issue_date: localDocument.date,
      total_required: localDocument.amount
    }
  }

  const prepareProtectedFiles = () => {
    return selectedFiles.map(file => ({
      provider_response_detail_id: file.id,
      file_name: file.file_name,
      content_type: file.content_type,
      url: file.url
    }))
  }

  const buildFormData = (documentId, insurerId) => {
    const formData = new FormData()
    
    // IDs principales
    formData.append('insurer_id', insurerId)
    formData.append('document_id', documentId)
    
    // Datos del documento
    const documentData = prepareDocumentData()
    Object.entries(documentData).forEach(([key, value]) => {
      formData.append(`document[${key}]`, value)
    })
    
    // Archivos protegidos
    const protectedFiles = prepareProtectedFiles()
    formData.append('document[protected_file_attributes]', JSON.stringify(protectedFiles))
    
    return formData
  }

  const handleSave = async () => {
    try {
      showLoading()
      // Preparar datos para el request
      const documentId = document.id
      const insurerId = window.insurerId
      const requirementId = window.requirementId
      const formData = buildFormData(documentId, insurerId)

      // Realizar request
      const response = await axios({
        url: updateEmptyDocumentInsurerExpense(insurerId, requirementId),
        method: 'POST',
        data: formData,
      })
      if (response.success) {
        alertWhileRedirect('success', 'Todo en orden', 'Documento actualizado correctamente', data.redirect_to)
        handleClose()
      } else {
        console.error('Error en la actualización:', response)
      }
      window.location.reload()
    } catch (error) {
      console.error('Error en la actualización:', error)
      handleClose()
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setLocalDocument(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleFileSelect = (responseDetail) => {
    setSelectedFiles(prev => {
      const isSelected = prev.some(file => file.url === responseDetail.url)
      if (isSelected) {
        return prev.filter(file => file.url !== responseDetail.url)
      } else {
        return [...prev, responseDetail]
      }
    })
  }

  return (
    <>
      <Button 
        variant="success" 
        onClick={handleShow}
        className="d-flex align-items-center justify-content-center add-button"
      >
        +
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header >
          <Modal.Title>Editar Documento</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={2} className="border-right">
              <div className="d-flex flex-column">
                <Form.Label className="mb-4 pt-2">Tipo de Documento</Form.Label>
                <Form.Label className="mb-4 pt-2">Prestador</Form.Label>
                <Form.Label className="mb-4 pt-2">Número Documento</Form.Label>
                <Form.Label className="mb-4 pt-2">Máquina/Caja</Form.Label>
                <Form.Label className="mb-4 pt-2">Fecha</Form.Label>
                <Form.Label className="mb-4 pt-2">Monto</Form.Label>
              </div>
            </Col>

            <Col md={4} className="border-right">
              <div className="d-flex flex-column">
                <Form.Select
                  name="documentTypeName"
                  value={localDocument.documentTypeName}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  disabled={false}
                >
                  <option value="">Seleccione un tipo de documento</option>
                  {flattenedOptions.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  type="text"
                  name="providerName"
                  value={localDocument.providerName}
                  onChange={handleChange}
                  placeholder="Ingrese el nombre del proveedor"
                  className="mb-3"
                  disabled={true}
                />
                <Form.Control
                  type="text"
                  name="externalCode"
                  value={localDocument.externalCode}
                  onChange={handleChange}
                  placeholder="Ingrese el código externo"
                  className="mb-3"
                />
                <Form.Control
                  type="text"
                  name="externalCodeDv"
                  value={localDocument.externalCodeDv}
                  onChange={handleChange}
                  placeholder="Ingrese la máquina/caja"
                  className="mb-3"
                />
                <Form.Control
                  type="date"
                  name="date"
                  value={localDocument.date}
                  onChange={handleChange}
                  className="mb-3"
                />
                <Form.Control
                  type="number"
                  name="amount"
                  value={localDocument.amount}
                  onChange={handleChange}
                  placeholder="Ingrese el monto"
                  step="0.01"
                  className="mb-3"
                  disabled={true}
                />
              </div>
            </Col>

            <Col md={5}>
              {providerBackgroundDetails.length > 0 ? (
                <>
                  <Card.Subtitle className="mb-2 text-muted">
                    <div className="d-flex justify-content-between align-items-center">
                      <strong>Seleccionar Archivos</strong>
                      <span className="text-primary">
                        {selectedFiles.length} archivo(s) seleccionado(s)
                      </span>
                    </div>
                  </Card.Subtitle>
                  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <ListGroup className="mb-3">
                      {providerBackgroundDetails.map((responseDetail, index) => {
                        const isSelected = selectedFiles.some(file => file.url === responseDetail.url)
                        return (
                          <ListGroup.Item 
                            key={index} 
                            className={`d-flex justify-content-between align-items-center ${isSelected ? 'list-group-item-primary' : ''}`}
                            style={{ padding: '0.75rem 1.25rem', cursor: 'pointer' }}
                            onClick={() => handleFileSelect(responseDetail)}
                          >
                            <div className={'d-flex align-items-center flex-grow-1'}>
                              <div className={'d-flex align-items-center justify-content-center text-info pointer-as-btn mx-3'}>
                                <FontAwesomeIcon icon={faFileDownload} size={'xl'}/>
                              </div>
                              <span className="text-truncate">{responseDetail.file_name}</span>
                            </div>
                            <Button 
                              variant="link" 
                              className="text-info ml-3"
                              onClick={(e) => {
                                e.stopPropagation();
                                openInNewTab(responseDetail.url);
                              }}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Ver archivo
                            </Button>
                          </ListGroup.Item>
                        )
                      })}
                    </ListGroup>
                  </div>
                </>
              ) : (
                <div className="text-center p-4 text-muted">
                  <p>No han llegado archivos desde el proveedor</p>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DocumentHeaderModalButton 