import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import openInNewTab from '../../../../utils/openInNewTab';

const BackgroundResponsesTable = ({
 data
}) => {

  return (
    <div>
      {
        data.map((backgroundResponse, index) => {
          return (
            <Card className="mb-2">
              <Card.Body className="pb-3">
                <Card.Title>{backgroundResponse.response_type} #{backgroundResponse.id}</Card.Title>
                <Card.Subtitle className="mb-3 text-muted">
                  Enviada por {backgroundResponse.provider_username ? backgroundResponse.provider_username : 'Usuario no especificado'} el {backgroundResponse.received_at}
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted"><strong>Archivos recibidos</strong></Card.Subtitle>
                <ListGroup className="mb-3">
                  {
                    backgroundResponse.response_details.map((responseDetail) => {
                      return (
                        <ListGroup.Item action onClick={() => openInNewTab(responseDetail.url)}>
                          <div className={'mb-2 d-flex align-items-center'}>
                            <div className={'d-flex mr-3 align-items-center justify-content-center text-info pointer-as-btn'}>
                              <FontAwesomeIcon icon={faFileDownload} className={'mr-2 mb-0'} size={'xl'}/>
                            </div>
                            {responseDetail.file_name}
                          </div>
                        </ListGroup.Item>
                      )
                    })
                  }
                </ListGroup>
                <Card.Text>
                  <div className="text-muted"><strong>Comentario de prestador</strong></div>
                  {
                    backgroundResponse.comment ?
                      backgroundResponse.comment
                      : <em>No se recibieron comentarios del prestador</em>
                  }
                </Card.Text>
              </Card.Body>
            </Card>
          )
        })
      }
    </div>
  );
};

export default BackgroundResponsesTable;