import React, { useState, useEffect } from 'react'
import axios from 'axios'
import closeAlert from '../../utils/closeAlert'
import showLoading from '../../utils/showLoading'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { getPolicyPremiumUrl, getSponsorsUrl, getProductInstancesUrl } from '../../routes/policyPremiums'
import Label from '../../shared/Label'
import Select from 'react-select'

const GeneralConfigurationsTab = ({ insurers }) => {
  const buildInsurerOption = (insurer) => {
    if (!insurer) {
      return {
        value: '',
        label: ''
      }
    }
    return {
      value: insurer.id,
      label: insurer.name
    }
  }

  const buildSponsorOption = (sponsor) => {
    if (!sponsor) {
      return {
        value: '',
        label: ''
      }
    }
    return {
      value: sponsor.id,
      label: sponsor.name
    }
  }

  const buildInstanceOption = (instance) => {
    if (!instance) {
      return {
        value: '',
        label: ''
      }
    }
    return {
      value: instance.id,
      label: instance.name
    }
  }
  
  const [sponsors, setSponsors] = useState([])
  const [instances, setInstances] = useState([])
  const [policyPremium, setPolicyPremium] = useState(null)
  const [selectedInsurer, setSelectedInsurer] = useState(buildInsurerOption(insurers[0]))
  const [selectedSponsor, setSelectedSponsor] = useState(buildSponsorOption(sponsors[0]))
  const [selectedInstance, setSelectedInstance] = useState(buildInstanceOption(instances[0]))
  const [premiums, setPremiums] = useState([])

  const searchPolicyPremium = async () => {
    if (!selectedInsurer.value || !selectedSponsor.value || !selectedInstance.value) {
      return
    }
    let formData = new FormData()
    formData.append('insurer_id', selectedInsurer.value)
    formData.append('sponsor_id', selectedSponsor.value)
    formData.append('polciy_id', null)
    formData.append('instance', selectedInstance.value)
    showLoading()
    await axios({
      url: getPolicyPremiumUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setPolicyPremium(value)
      })
      .catch(err =>{
        setPolicyPremium(null)
      })
      .finally(() => {
        closeAlert()
      })
  }

  const searchSponsors = async () => {
    let formData = new FormData()
    formData.append('insurer_id', selectedInsurer.value)
    showLoading()
    await axios({
      url: getSponsorsUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setSponsors(value)
        setSelectedSponsor(buildSponsorOption(value[0]))
      })
      .catch(err =>{
        setSponsors([])
        setSelectedSponsor(buildSponsorOption(null))
      })
      .finally(() => {
        closeAlert()
      })
  }

  const searchInstances = async () => {
    let formData = new FormData()
    formData.append('insurer_id', selectedInsurer.value)
    formData.append('sponsor_id', selectedSponsor.value)
    showLoading()
    await axios({
      url: getProductInstancesUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setInstances(value)
        setSelectedInstance(buildInstanceOption(value[0]))
      })
      .catch(err =>{
        setInstances([])
        setSelectedInstance(buildInstanceOption(null))
      })
      .finally(() => {
        closeAlert()
      })
  }

  const getInsurersOptions = () => {
    let options = insurers.map((insurer) => {
        return buildInsurerOption(insurer)
      })
    return options
  }

  const getSponsorsOptions = () => {
    let options = sponsors.map((sponsor) => {
        return buildSponsorOption(sponsor)
      })
    return options
  }

  const getInstancesOptions = () => {
    let options = instances.map((instance) => {
        return buildInstanceOption(instance)
      })
    return options
  }

  useEffect(() => {
    if (selectedInsurer.value) {
      searchSponsors()
    }else{
      if (selectedSponsor.value) {
        setSponsors([])
        setSelectedSponsor(buildSponsorOption(null))
      }
    }
  }, [selectedInsurer])

  useEffect(() => {
    if (selectedSponsor.value) {
      searchInstances()
    }else{
      if (selectedInstance.value) {
        setInstances([])
        setSelectedInstance(buildInstanceOption(null))
      }
    }
  }, [selectedSponsor])

  useEffect(() => {
    if (selectedInstance.value) {
      searchPolicyPremium()
    }else{
      if (policyPremium) {
        setPolicyPremium(null)
      }
    }
  }, [selectedInstance])
  

  return (
    <div>
      <div className={'form-group'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'general_configurations_insurer'}
          title={'Compañía'}
        >
          Compañía:
        </Label>
        <Select
          id={'general_configurations_insurer'}
          className={'col'}
          placeholder={''}
          name={'insurer'}
          value={selectedInsurer}
          onChange={(e) => setSelectedInsurer(e)}
          options={getInsurersOptions()}
        />  
      </div>
      <div className={'form-group'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'general_configurations_sponsor'}
          title={'Sponsor'}
        >
          Sponsor:
        </Label>
        <Select
          id={'general_configurations_sponsor'}
          className={'col'}
          placeholder={''}
          name={'sponsor'}
          value={selectedSponsor}
          onChange={(e) => setSelectedSponsor(e)}
          options={getSponsorsOptions()}
        />
      </div>
      <div className={'form-group'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'general_configurations_instance'}
          title={'Vigencia'}
        >
          Vigencia:
        </Label>
        <Select
          id={'general_configurations_instance'}
          className={'col'}
          placeholder={''}
          name={'instance'}
          value={selectedInstance}
          onChange={(e) => setSelectedInstance(e)}
          options={getInstancesOptions()}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Edad Desde</TableCell>
            <TableCell>Edad Hasta</TableCell>
            <TableCell>Cantidad de Dependientes</TableCell>
            <TableCell>Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {premiums.map((premium) => (
            <TableRow key={premium.id}>
              <TableCell>{premium.age_from}</TableCell>
              <TableCell>{premium.age_to}</TableCell>
              <TableCell>{premium.dependents_quantity}</TableCell>
              <TableCell>{premium.amount_cents}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default GeneralConfigurationsTab
