import showLoading from './showLoading'
import axios from 'axios'
import errorSwalAlert from './errorSwalAlert'
import alertWhileRedirect from './alertWhileRedirect'
import closeAlert from './closeAlert'

const simpleRequestConciliation = async (data, url, successText, errorText, redirect) => {
    let closeAlerts = true
    showLoading()
    await axios({
      url: url,
      method: 'PUT',
      data: data
    })
    .then ((res) => {
      let data = res.data
      if (!data.success) {
        closeAlerts = false
        // Error controlado desde el backend
        return errorSwalAlert(data.message)
      }
      if (data.redirect_to && (redirect === undefined || redirect)) {
        closeAlerts = false
        alertWhileRedirect('success', 'Todo en orden', successText, data.redirect_to)
      }
    })
    .catch(err =>{
      // Error
      closeAlerts = false
      errorSwalAlert(errorText)
      // Para trazabilidad
      console.log(err)
    })
    .finally(() => {
      if (closeAlerts) {
        closeAlert()
      }
    })
  }
  
export default simpleRequestConciliation
