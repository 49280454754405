import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import BackgroundRequestsTable from './BackgroundRequestsTable';
import BackgroundResponsesTable from './BackgroundResponsesTable';

const ConciliationLogsModal = (
  {
    showConciliationLogsModal,
    setShowConciliationLogsModal,
    requirement
  }
) => {

  const providerBackgroundRequests = requirement.providerBackgroundRequests || [];
  const providerBackgroundResponses = requirement.providerBackgroundResponses || [];

  const handleClose = () => {
    setShowConciliationLogsModal(false);
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered='true'
      scrollable='true'
      show={showConciliationLogsModal}
      onHide={handleClose}
      className={'conciliation-logs-modal'}
    >
      <Modal.Header>
        <Modal.Title>Historial de Conciliación de Reembolso en Línea</Modal.Title>
        <button type={'button'} className={'close'} onClick={handleClose}>
          <span aria-hidden={'true'}>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body bsPrefix='' className={'conciliation-logs-body'}>
        <Tabs
          className={'mb-2'}
          defaultActiveKey='backgroundRequests'
        >
          <Tab
            eventKey='backgroundRequests'
            title='Solicitudes a Prestador'
          >
            {
              providerBackgroundRequests.length > 0 ?
                <BackgroundRequestsTable data={providerBackgroundRequests}></BackgroundRequestsTable>
              : <Alert variant="info">
                  <Alert.Heading>Atención</Alert.Heading>
                  <p>No hay solicitudes de antecedentes realizadas al prestador</p>
                </Alert>
            }
          </Tab>
          <Tab
            eventKey='backgroundResponses'
            title='Respuestas de Prestador'
          >
            {
              providerBackgroundResponses.length > 0 ?
                <BackgroundResponsesTable data={providerBackgroundResponses}></BackgroundResponsesTable>
              : <Alert variant="info">
                  <Alert.Heading>Atención</Alert.Heading>
                  <p>El prestador no ha enviado antecedentes para revisión</p>
                </Alert>
            }
          </Tab>
        </Tabs>
      </Modal.Body>

    </Modal>
  );
};

export default ConciliationLogsModal;