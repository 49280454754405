import React from 'react'
import DocumentHeaderRemoveDocument from './DocumentHeaderRemoveDocument'
import DocumentHeaderProvider from './DocumentHeaderProvider'
import DocumentHeaderDocumentType from './DocumentHeaderDocumentType'
import DocumentHeaderExternalCode from './DocumentHeaderExternalCode'
import DocumentHeaderDate from './DocumentHeaderDate'
import DocumentHeaderAmount from './DocumentHeaderAmount'
import DocumentHeaderActionButtons from './DocumentHeaderActionButtons'
import DocumentHeaderModalButton from './DocumentHeaderModalButton'
import DuplicatedDocumentReject from '../shared/DuplicatedDocumentReject'

const DocumentHeader = (
  {
    document,
    setDocument,
    removeDocument,
    index,
    documentTypeOptions,
    attachments,
    accountingDocuments,
    isModal,
    checkDuplicatedDocument,
    requirementType,
    countryIso,
    isEditable,
    providerBackgroundResponses,
    showModalConciliationDocuments
  }
) => {

  return (
    <div className={'row col-12 d-flex justify-content-between align-items-center document-header'}>
      {document.hasProvisionDetails && (
        <DuplicatedDocumentReject
          {...
            {
              document,
              accountingDocuments
            }
          }
          className={'row col-12 d-flex justify-content-center align-items-center'}
        />
      )}
      <div className={'row col d-flex align-items-center pl-0'}>
        <div className={'col document-header__remove-document'}>
          {isEditable &&<DocumentHeaderRemoveDocument
            {...
              {
                removeDocument,
                index
              }
            }
          />}
        </div>
        <div className={'col document-header__provider'}>
          <DocumentHeaderProvider
            {...
              {
                document,
                setDocument,
                index,
                checkDuplicatedDocument,
                requirementType,
                countryIso,
                isEditable
              }
            }
          />
        </div>
        <div className={'col document-header__document-type'}>
          <DocumentHeaderDocumentType
            {...
              {
                document,
                setDocument,
                index,
                documentTypeOptions,
                isModal,
                isEditable
              }
            }
          />
        </div>
        <div className={'col document-header__external-code'}>
          <DocumentHeaderExternalCode
            {...
              {
                document,
                setDocument,
                index,
                checkDuplicatedDocument,
                isEditable
              }
            }
          />
        </div>
        <div className={'col document-header__date'}>
          <DocumentHeaderDate
            {...
              {
                document,
                setDocument,
                index,
                checkDuplicatedDocument,
                isEditable
              }
            }
          />
        </div>
        <div className={'col document-header__amount'}>
          <DocumentHeaderAmount
            {...
              {
                document,
                setDocument,
                index,
                isEditable
              }
            }
          />
        </div>
      </div>
      <div className={'row col document-header__action-buttons'}>
        {isEditable && (
          <>
            <DocumentHeaderActionButtons
              {...
                {
                  document,
                  setDocument,
                  attachments,
                  index
                }
              }
            />
          </>
        )}
      </div>
      <div className={'row col document-header__add-document'}>
        {showModalConciliationDocuments && (
          <DocumentHeaderModalButton 
            {...
              {
                document,
                setDocument,
                index,
                providerBackgroundResponses,
                documentTypeOptions
            }
            }
          />
        )}
      </div>
    </div>
  )
}

export default DocumentHeader