import simpleRequestConciliation from './simpleRequestConciliation'

const conciliationRequest = async (data, url, redirect) => {
  simpleRequestConciliation(
    data,
    url,
    'Operación realizada con exito. Espere unos momentos mientras es redirigido.',
    'Ocurrió un error inesperado al intentar aprobar o rechazar el reembolso. Intente nuevamente',
    redirect
  )
}

export default conciliationRequest