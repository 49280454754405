import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const BackgroundRequestsTable = ({
  data
}) => {

  const getTitle = (backgroundRequest) => {
    switch (backgroundRequest.request_type) {
      case 'reject_background_expired':
        return `Plazo de envío de antecedentes expirado #${backgroundRequest.id}`;
      case 'reject_appeal_expired':
        return `Plazo de apelación expirado #${backgroundRequest.id}`;
      case 'background':
        return `Solicitud de antecedentes #${backgroundRequest.id}`;
      case 'reject':
        return `Rechazado #${backgroundRequest.id}`;
      case 'approve':
        return `Aprobado #${backgroundRequest.id}`;
      default:
        return `No se identificó el tipo de solicitud #${backgroundRequest.id}`;
    }
  };

  return (
    <div className="background-requests-table">
      {
        data.map((backgroundRequest, index) => {
          return (
            <Card className="mb-2">
              <Card.Body className="pb-3">
                <Card.Title>{getTitle(backgroundRequest)}</Card.Title>
                <Card.Subtitle className="mb-3 text-muted">Realizada el {backgroundRequest.requested_at} CLT por {backgroundRequest.liquidator_name}</Card.Subtitle>
                { backgroundRequest.background_reasons.length > 0 && (
                  <>
                    <Card.Subtitle className="mb-2 text-muted"><strong>Motivos de solicitud de antecedentes</strong></Card.Subtitle>
                    <ListGroup className="mb-2">
                      {
                      backgroundRequest.background_reasons.map((backgroundReason) => {
                        return (
                          <ListGroup.Item action>{backgroundReason.code} - {backgroundReason.description}</ListGroup.Item>
                        )
                      })
                    }
                    </ListGroup>
                  </>
                )}
                {
                  backgroundRequest.due_date && backgroundRequest.request_type === 'background' && (
                    <Card.Text><strong>Permitir envío de antecedentes hasta {backgroundRequest.due_date} CLT</strong></Card.Text>
                  )
                }
                {
                  backgroundRequest.due_date && backgroundRequest.request_type === 'reject' && (
                    <Card.Text><strong>Permitir apelación hasta {backgroundRequest.due_date}</strong></Card.Text>
                  )
                }
                {
                  !backgroundRequest.due_date && backgroundRequest.request_type === 'reject' && (
                    <Card.Text><strong>No se permite apelación</strong></Card.Text>
                  )
                }
                <Card.Text>
                  <div className="text-muted"><strong>Comentario de liquidador</strong></div>
                  {
                    backgroundRequest.comments ?
                      backgroundRequest.comments
                      : <em>No se registraron comentarios</em>
                  }
                </Card.Text>
              </Card.Body>
            </Card>
          )
        })
      }
    </div>
  );
};

export default BackgroundRequestsTable;

