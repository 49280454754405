import baseApiV2 from './baseApiV2'

const baseApiV2PolicyPremiums = baseApiV2 + 'policy_premiums'

export const getSponsorsUrl = baseApiV2PolicyPremiums + '/sponsors'

export const getPolicyInstancesUrl = baseApiV2PolicyPremiums + '/policy_instances'

export const getProductInstancesUrl = baseApiV2PolicyPremiums + '/product_instances'

export const getPoliciesUrl = baseApiV2PolicyPremiums + '/policies'

export const getPolicyPremiumUrl = baseApiV2PolicyPremiums + '/policy_premium'
