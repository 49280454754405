import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import closeAlert from '../../utils/closeAlert'
import showLoading from '../../utils/showLoading'
import { getPolicyPremiumUrl, getPoliciesUrl, getPolicyInstancesUrl } from '../../routes/policyPremiums'
import Label from '../../shared/Label'
import Select from 'react-select'


const IndividualConfigurationsTab = ({ insurers }) => {

  const buildInsurerOption = (insurer) => {
    if (!insurer) {
      return {
        value: '',
        label: ''
      }
    }
    return {
      value: insurer.id,
      label: insurer.name
    }
  }

  const buildPolicyOption = (policy) => {
    if (!policy) {
      return {
        value: '',
        label: ''
      }
    }
    return {
      value: policy.id,
      label: policy.number + ' - ' + policy.subsidiary_name
    }
  }

  const buildInstanceOption = (instance) => {
    if (!instance) {
      return {
        value: '',
        label: ''
      }
    }

    let startDate = new Date(instance.start_date),
      endDate = new Date(instance.end_date),
      formattedStartDate = startDate.toLocaleDateString('es-CL'),
      formattedEndDate = endDate.toLocaleDateString('es-CL'),
      sequenceNumber = parseInt(instance.sequence_number),
      formattedSequenceNumber = isNaN(sequenceNumber) ? '0' : sequenceNumber

    return {
      value: instance.id,
      label: '[' + formattedSequenceNumber + '] ' + formattedStartDate + ' / ' + formattedEndDate
    }
  }

  const [isEditing, setIsEditing] = useState(false)
  const [policies, setPolicies] = useState([])
  const [instances, setInstances] = useState([])
  const [policyPremium, setPolicyPremium] = useState(null)
  const [selectedInsurer, setSelectedInsurer] = useState(buildInsurerOption(insurers[0]))
  const [selectedPolicy, setSelectedPolicy] = useState(buildPolicyOption(policies[0]))
  const [selectedInstance, setSelectedInstance] = useState(buildInstanceOption(instances[0]))
  const [premiums, setPremiums] = useState([])

  const handleEdit = () => {
    setIsEditing(true)
  };

  const handleSave = () => {
    // Lógica para guardar los cambios
    setIsEditing(false)
  }

  const searchPolicyPremium = async () => {
    if (!selectedInsurer.value || !selectedPolicy.value || !selectedInstance.value) {
      return
    }
    let formData = new FormData()
    formData.append('insurer_id', selectedInsurer.value)
    formData.append('sponsor_id', null)
    formData.append('polciy_id', selectedPolicy.value)
    formData.append('instance', selectedInstance.value)
    showLoading()
    await axios({
      url: getPolicyPremiumUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setPolicyPremium(value)
      })
      .catch(err =>{
        setPolicyPremium(null)
      })
      .finally(() => {
        closeAlert()
      })
  }

  const searchPolicies = async () => {
    let formData = new FormData()
    formData.append('insurer_id', selectedInsurer.value)
    showLoading()
    return await axios({
      url: getPoliciesUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setPolicies(value)
        setSelectedPolicy(buildPolicyOption(value[0]))
      })
      .catch(err =>{
        setPolicies([])
        setSelectedPolicy(buildPolicyOption(null))
      })
      .finally(() => {
        closeAlert()
      })
  }

  const searchInstances = async () => {
    let formData = new FormData()
    formData.append('insurer_id', selectedInsurer.value)
    formData.append('policy_id', selectedPolicy.value)
    showLoading()
    await axios({
      url: getPolicyInstancesUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setInstances(value)
        setSelectedInstance(buildInstanceOption(value[0]))
      })
      .catch(err =>{
        setInstances([])
        setSelectedInstance(buildInstanceOption(null))
      })
      .finally(() => {
        closeAlert()
      })
  }

  const getInsurersOptions = () => {
    let options = insurers.map((insurer) => {
        return buildInsurerOption(insurer)
      })
    return options
  }

  const getPoliciesOptions = () => {
    let options = policies.map((policy) => {
        return buildPolicyOption(policy)
      })
    return options
  }

  const getInstancesOptions = () => {
    let options = instances.map((instance) => {
        return buildInstanceOption(instance)
      })
    return options
  }

  useEffect(() => {
    if (selectedInsurer.value) {
      searchPolicies()
    }else{
      if (selectedPolicy.value) {
        setPolicies([])
        setSelectedPolicy(buildPolicyOption(null))
      }
    }
  }, [selectedInsurer])

  useEffect(() => {
    if (selectedPolicy.value) {
      searchInstances()
    }else{
      if (selectedInstance.value) {
        setInstances([])
        setSelectedInstance(buildInstanceOption(null))
      }
    }
  }, [selectedPolicy])

  useEffect(() => {
    if (selectedInstance.value) {
      searchPolicyPremium()
    }else{
      if (policyPremium) {
        setPolicyPremium(null)
      }
    }
  }, [selectedInstance])

  return (
    <div>
      <div className={'form-group'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'individual_configurations_insurer'}
          title={'Compañía'}
        >
          Compañía:
        </Label>
        <Select
          id={'individual_configurations_insurer'}
          className={'col'}
          placeholder={''}
          name={'insurer'}
          value={selectedInsurer}
          onChange={(e) => setSelectedInsurer(e)}
          options={getInsurersOptions()}
        />  
      </div>
      <div className={'form-group'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'individual_configurations_policy'}
          title={'Póliza'}
        >
          Póliza:
        </Label>
        <Select
          id={'individual_configurations_policy'}
          className={'col'}
          placeholder={''}
          name={'policy'}
          value={selectedPolicy}
          onChange={(e) => setSelectedPolicy(e)}
          options={getPoliciesOptions()}
        />  
      </div>
      <div className={'form-group'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'individual_configurations_instance'}
          title={'Vigencia'}
        >
          Vigencia:
        </Label>  
        <Select
          id={'individual_configurations_instance'}
          className={'col'}
          placeholder={''}
          name={'instance'}
          value={selectedInstance}
          onChange={(e) => setSelectedInstance(e)}
          options={getInstancesOptions()}
        />  
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Edad Desde</TableCell>
            <TableCell>Edad Hasta</TableCell>
            <TableCell>Cantidad de Dependientes</TableCell>
            <TableCell>Monto</TableCell>
            {isEditing && <TableCell>Acciones</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {premiums.map((premium) => (
            <TableRow key={premium.id}>
              <TableCell>{premium.age_from}</TableCell>
              <TableCell>{premium.age_to}</TableCell>
              <TableCell>{premium.dependents_quantity}</TableCell>
              <TableCell>
                {isEditing ? (
                  <TextField value={premium.amount_cents} onChange={(e) => handleAmountChange(e, premium.id)} />
                ) : (
                  premium.amount_cents
                )}
              </TableCell>
              {isEditing && (
                <TableCell>
                  <Button onClick={handleSave}>Guardar</Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!isEditing && <Button onClick={handleEdit}>Editar</Button>}
    </div>
  )
}

export default IndividualConfigurationsTab
