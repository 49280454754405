import React, {useState} from 'react'
import BackgroundConciliationItem from './BackgroundConciliationItem'

const BackgroundConciliationItems = (
  {
    initialBackgrounds,
    backgrounds,
    setBackgrounds,
    backgroundConciliationOptions,
    removedBackgrounds,
    setRemovedBackgrounds
  }
) => {

  const [iBackgrounds, setInitialBackgrounds] = useState(initialBackgrounds)

  const handleBackgroundRemove = (backgroundKey) => {
    let filteredBackgrounds = backgrounds.filter((_, index) => parseInt(index) !== parseInt(backgroundKey))
    setBackgrounds(filteredBackgrounds)
  }

  const handleInitialBackgroundRemove = (backgroundKey) => {
    let filteredBackgrounds = iBackgrounds.filter((_, index) => parseInt(index) !== parseInt(backgroundKey)),
      backgroundToDelete = iBackgrounds.filter((_, index) => parseInt(index) === parseInt(backgroundKey))
    setRemovedBackgrounds([...removedBackgrounds, backgroundToDelete[0].id])
    setInitialBackgrounds(filteredBackgrounds)
  }

  const updateArray = (array, key, value, callback) => {
    const newArray = array.map((item, index) => {
      if (parseInt(index) === parseInt(key)) {
        return value
      }
      return item
    })
    callback(newArray)
  }

  const updateInitialBackground = (backgroundKey, background) => {
    updateArray(iBackgrounds, backgroundKey, background, setInitialBackgrounds)
  }

  const updateBackground = (backgroundKey, background) => {
    updateArray(backgrounds, backgroundKey, background, setBackgrounds)
  }

  return (
    <>
      <h4 className={'mt-3'}>Antecedentes faltantes:</h4>
      {backgrounds.length > 0 || iBackgrounds.length > 0 ? (
        <>
          {iBackgrounds.map((background, index) => (
            <BackgroundConciliationItem
              background={background}
              backgroundKey={index}
              key={index}
              backgroundConciliationOptions={backgroundConciliationOptions}
              updateBackground={updateInitialBackground}
              handleBackgroundRemove={handleInitialBackgroundRemove}
              showBackgroundRemoveButton={true}
            />
          ))}
          {backgrounds.map((background, index) => (
            <BackgroundConciliationItem
              background={background}
              backgroundKey={index}
              key={iBackgrounds.length + index}
              backgroundConciliationOptions={backgroundConciliationOptions}
              updateBackground={updateBackground}
              handleBackgroundRemove={handleBackgroundRemove}
              showBackgroundRemoveButton={true}
            />
          ))}
        </>
      ) : (
        <p className={'empty_content'}>No hay antecedentes</p>
      )}
    </>
  )
}

export default BackgroundConciliationItems